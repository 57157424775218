import { Alert, Button, Card, CardProps, Divider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useFetcher } from "@remix-run/react";
import { useCallback, useEffect, useState } from "react";
import { ValidatedForm } from "remix-validated-form";
import BazaarTextField from "~/components/BazaarTextField";
import { H3 } from "~/components/Typography";
import { FlexBox } from "~/components/flex-box";
import { SubmitButton } from "~/components/form";
import {
  action,
  loginValidator,
} from "~/route-containers/authentication/login";
import EyeToggleButton from "./EyeToggleButton";

export { action };

type WrapperProps = { passwordVisibility?: boolean };

export const Wrapper = styled<React.FC<WrapperProps & CardProps>>(
  ({ children, passwordVisibility, ...rest }) => (
    <Card {...rest}>{children}</Card>
  )
)<CardProps>(({ theme, passwordVisibility }) => ({
  width: 500,
  padding: "2rem 3rem",
  [theme.breakpoints.down("sm")]: { width: "100%" },
  ".passwordEye": {
    color: passwordVisibility
      ? theme.palette.grey[600]
      : theme.palette.grey[400],
  },
}));

type SigninProps = {
  message: string;
  redirectTo?: string;
  isBusiness?: boolean;
};

const Signin: React.FC<SigninProps> = ({
  message,
  redirectTo = "/",
  isBusiness = false,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [value, setValue] = useState("");
  const [hostname, setHostname] = useState("");
  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setValue(result);
  };

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility((visible) => !visible);
  }, []);

  const fetcher = useFetcher();

  useEffect(() => {
    setHostname(window.location.href);
  }, []);
  let length = 20;
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_*+";
  let result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }

  return (
    <Wrapper elevation={3} passwordVisibility={passwordVisibility}>
      <ValidatedForm
        key="login-form"
        id="login-form"
        action="/login"
        noValidate={true}
        validator={loginValidator}
        fetcher={fetcher}
        method="post"
      >
        <H3 textAlign="center" mb={1}>
          {isBusiness ? "Бизнес картаар нэвтрэх" : "Нэвтрэх"}
        </H3>

        {fetcher.state === "idle" && fetcher.data != null && (
          <Alert severity="error" style={{ marginBottom: 10 }}>
            {fetcher.data.message}
          </Alert>
        )}
        {message && (
          <Alert severity="error" style={{ marginBottom: 10 }}>
            {message}
          </Alert>
        )}

        <input type="hidden" name="redirectTo" value={redirectTo} />

        <BazaarTextField
          mb={1.5}
          fullWidth
          name="username"
          size="medium"
          type="text"
          variant="outlined"
          value={value}
          onChange={handleChange}
          label={
            isBusiness
              ? "Бизнес картын дугаар"
              : "Номин Бонус Карт / Утасны дугаар"
          }
          placeholder=""
        />

        <BazaarTextField
          mb={1.5}
          fullWidth
          size="medium"
          name="password"
          label="Нууц үг"
          autoComplete="on"
          variant="outlined"
          placeholder="*********"
          type={passwordVisibility ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <EyeToggleButton
                show={passwordVisibility}
                click={togglePasswordVisibility}
              />
            ),
          }}
        />

        <SubmitButton
          label="Нэвтрэх"
          isLoading={fetcher.state !== "idle"}
          fullWidth={true}
          id="login"
        />
        <FlexBox
          sx={{
            mt: 2,
            justifyContent: "space-evenly",
            color: "#2D68C4",
            textDecoration: "underline",
            textUnderlineOffset: "5px",
          }}
        >
          {/* <Link to="">Шинээр бүртгүүлэх</Link> */}
          <Link to="/login/forgot">Нууц үгээ сэргээх</Link>
        </FlexBox>
        {!isBusiness && (
          <>
            <Divider variant="middle" sx={{ my: 2 }}>
              эсвэл
            </Divider>
            <Link
              to={
                "https://auth.upoint.mn/authorize?client_id=gOrI2KROrr6Csv&response_type=code&response_mode=query&scope=openid&redirect_uri=" +
                hostname +
                "/upoint&state=" +
                result
              }
            >
              <Button
                variant="contained"
                fullWidth
                size="medium"
                sx={{
                  background: "#6C5CE7",
                  color: "#FFF",
                  "&:hover": { background: "#584bbf" },
                }}
              >
                <img
                  src={"/assets/images/logos/upoint.svg"}
                  alt="upoint_logo"
                />
                <Box sx={{ ml: 1, fontWeight: 500, lineHeight: 1.75 }}>
                  U-Point картаар нэвтрэх
                </Box>
              </Button>
            </Link>
          </>
        )}
      </ValidatedForm>
    </Wrapper>
  );
};

export default Signin;
